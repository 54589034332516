@import 'utils/Stylesheet/colors.scss';

@mixin section {
    margin-top: 16px;
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(57, 68, 105, 0.05);
}
@mixin left-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.score {
    text-align: left;
    .heading {
        font-family: Poppins, sans-serif;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 2.0625rem;
        color: $primary_black;
    }
}
