@mixin font-details($font-family, $font-size, $font-weight, $line-height, $text-align: null) {
    font-family: $font-family;
    font-size: $font-size;
    font-weight: $font-weight;
    line-height: $line-height;
    @if ($text-align) {
        text-align: $text-align;
    }
}

@mixin flex($align, $direction: row, $justify: flex-start) {
    display: flex;
    justify-content: $justify;
    flex-direction: $direction;
    @if ($align) {
        align-items: $align;
    }
}
