.login-screen {
    height: 100vh;
    padding-top: 25vh;
    background: #fafafa;
    p {
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 16px;
        color: #90949f;
    }
    .link {
        color: #7c98ff;
        cursor: pointer;
    }
    h5 {
        font-style: normal;
        font-weight: 600;
        font-size: 1.5rem;
        color: #4e515b;
        margin-bottom: 16px;
        @media only screen and (max-width: 1200px) {
            font-size: 1.125rem;
        }
    }
    .login-content-container {
        padding: 50px;
        width: 556px;
        height: 307px;
        background: #ffffff;
        box-shadow: 0px 8px 24px rgba(224, 232, 254, 0.15);
        border-radius: 6px;
        margin: auto;
        margin-bottom: 16px;
        @media only screen and (max-width: 1200px) {
            width: 90vw;
            padding: 15px;
        }
        .ew-logo-login {
            margin-bottom: 20px;
        }
    }
    .WrapperCTA {
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ffffff;
            border: 1px solid #441548;
            box-sizing: border-box;
            border-radius: 5px;
            width: 340px;
            height: 50px;
            font-weight: bold;
            font-size: 0.75rem;
            line-height: 58px;
            color: #333333;
            transition: all 0.5s;
            padding: 0;
            margin-bottom: -16px;

            @media only screen and (max-width: 1200px) {
                width: 201px;
                border: 1px solid #441548;
                margin: 0 0 14px 0;
            }
            img {
                margin-right: 6px;
            }
            &:hover {
                background: #441548;
                color: #ffffff;
                transition: all 0.4s;
            }
            &:last-child {
                margin: 0;
                border: 1px solid #464775;
                @media only screen and (max-width: 1200px) {
                    margin: 0;
                }
                &:hover {
                    background: #464775;
                    color: #ffffff;
                    transition: all 0.4s;
                }
            }
        }
    }
}
