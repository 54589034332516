$blue_1: #5d5cf5;
$blue_2: #4c4bdb;
$blue_3: rgba(93, 92, 245, 0.8);
$blue_4: #aeaefa;
$blue_5: #dfdefd;
$blue_6: rgba(93, 92, 245, 0.1);
$blue_7: #cfd3ff;
$blue_8: #f4f6ff;
$blue_9: rgba(94, 97, 236, 0.1);
$blue_10: #0029ff;
$blue_11: #3c4d7d;
$blue_12: #5d5cf0;
$blue_13: rgba(239, 239, 254, 0.5);
$blue_14: #656886;
$blue_15: rgba(21, 34, 47, 0.5);
$blue_16: #7f7eff;
$blue_17: #5d5cf514;
$blue_18: rgba(93, 92, 245, 0.03);
$blue_19: #5d5cf533;
$blue_19: #e0e8ff;

$red_1: #ff5353;
$red_2: #e84f48;
$red_3: #e84f4814;
$orange_1: #ff5b54;
$primary_black: #4e515b;
$primary_foreground: #1d1c1d;
$black_1: #15222f;
$black_2: #535961;
$black_3: #15222fb2;
$black_4: #5d5cf514;
$black_5: #153a51;
$black_6: #101828;
$black: #000000;

$white_1: #fafafa;
$white_2: #fcfcfc;
$white_3: #e5e7ea;
$white_4: #f7f7fe;
$white_5: #f5f5f5;
$white_6: #dddeea;
$white_7: #efeffe;
$white: #ffffff;

$grey_1: #82878d;
$grey_2: #a9adb1;
$grey_3: #a5adbb;
$grey_4: #90949f;
$grey_5: #c4c4c4;
$grey_6: #545454;
$grey_7: #e5e5e5;
$grey_8: #3c4d7d;
$grey_9: #727171;
$grey_10: #4a4a4a;
$grey_11: rgba(78, 81, 91, 0.7);
$grey_12: #9fabcc;
$grey_13: #8a9096;
$grey_14: #15222f99;
$grey_15: #4e515b;
$grey_16: #f6f6f6;

$grey_border: #e5e7ea;

$tag: #f1f3f6;

$line: #ccd5e1;

$dark_green: #1eb89c;
$green_1: #03b575;
$green_2: #03b57514;
$green_3: #f3fbf1;

$promoter: #48bb78;
$detractor: #ff6760;
$passive: #fac032;

$yellow_1: #ffeec3;
$yellow_2: #efad0a;
$yellow_3: #efad0a14;
$yellow_4: #ffdf8e;
$yellow_5: #ffeaa019;
$yellow_6: #ffeaa099;
$light_blue: #dceaff;
