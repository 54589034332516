@import 'utils/Stylesheet/colors.scss';
@import 'utils/Stylesheet/mixins.scss';

.activities-container {
    position: relative;
    width: 100%;
    padding-left: 2px;

    .activities-toggle {
        position: absolute;
        top: -6px;
        right: 0;
        box-sizing: border-box;

        .toggle-outlined {
            color: $blue_1;
            border: 1px solid $blue_1;
            background-color: white;
        }
        .toggle-filled {
            background-color: $blue_1;
            color: white;
            border: 1px solid $blue_1;
        }
        > button {
            padding: 8px 34px;
            display: inline-block;
            font-size: 0.875rem;
            cursor: pointer;
            &:first-child {
                border-radius: 6px 0px 0px 6px;
            }
            &:last-child {
                border-radius: 0px 6px 6px 0px;
            }
        }
    }

    .activities-table-container {
        background-color: white;
        box-shadow: 0px 0px 8px rgba(57, 68, 105, 0.05);
        border-radius: 8px;
        padding: 16px;
        min-height: calc(100vh - 250px);
    }

    .filters {
        @include flex(row, space-between, flex-start);
    }

    .ac-btn-download {
        margin-top: auto;
        height: 36px;
        padding: 9px 18px;
        background: $blue_6;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: $blue_1;
    }
}
