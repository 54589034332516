@mixin font-details(
    $font-family: Open Sans,
    $font-size: 1rem,
    $font-weight: 400,
    $line-height: null,
    $color: null,
    $text-align: null
) {
    font-family: $font-family, sans-serif !important;
    font-size: $font-size !important;
    font-weight: $font-weight !important;
    @if ($line-height) {
        line-height: $line-height !important;
    }
    @if ($text-align) {
        text-align: $text-align !important;
    }
    @if ($color) {
        color: $color !important;
    }
}

@mixin flex($direction: row, $justify: flex-start, $align: null) {
    display: flex;
    flex-direction: $direction;
    justify-content: $justify;
    @if ($align) {
        align-items: $align;
    }
}

@mixin text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin modalContainer($minHeight: null, $minWidth: null) {
    @if ($minHeight) {
        min-height: $minHeight;
    }
    @if ($minWidth) {
        min-width: $minWidth;
    }
    background-color: white;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 12px;
    border: 1px solid $grey_border;
    &:focus {
        outline: 0px !important;
    }
    &:focus-visible {
        outline: 0px !important;
    }
}

@mixin sideOverlay($minHeight: null, $minWidth: null) {
    @if ($minHeight) {
        min-height: $minHeight;
    }
    @if ($minWidth) {
        min-width: $minWidth;
    }
    background-color: white;
    position: absolute;
    right: 0%;
    top: 0%;
    transform: translate(-0%, -0%);
    padding: 16px;
    border: 1px solid $grey_border;
    &:focus {
        outline: 0px !important;
    }
    &:focus-visible {
        outline: 0px !important;
    }
}

@mixin text-line-clamp($lines: 1) {
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
